import React from 'react';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { FloatLabel } from 'primereact/floatlabel';
import AutoFocusDropdown from '../../../../components/AutoFocusDropdown';
import { InputNumber } from 'primereact/inputnumber';

const GCamFE = (props) => {
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const listaIndicadorPresencia = [
        {
            i_ind_pres: 1,
            d_des_ind_pres: 'Operación presencial'
        },
        {
            i_ind_pres: 2,
            d_des_ind_pres: 'Operación electrónica'
        },
        {
            i_ind_pres: 3,
            d_des_ind_pres: 'Operación telemarketing'
        },
        {
            i_ind_pres: 4,
            d_des_ind_pres: 'Venta a domicilio'
        },
        {
            i_ind_pres: 5,
            d_des_ind_pres: 'Operación bancaria'
        },
        {
            i_ind_pres: 6,
            d_des_ind_pres: 'Operación cíclica'
        }
    ];

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que componen la Factura electrónica</b>
                </Divider>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <AutoFocusDropdown
                            id="modVenta"
                            checkmark={true}
                            value={props.formik.values.modalidad_venta}
                            onChange={(e) => props.formik.setFieldValue('modalidad_venta', e.value)}
                            onBlur={() => props.formik.setFieldTouched('modalidad_venta', true)}
                            options={listaIndicadorPresencia}
                            optionLabel="d_des_ind_pres"
                            placeholder="Seleccione la modalidad de venta"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('modalidad_venta') })}
                        />
                        <label htmlFor="modVenta">Modalidad de venta</label>
                        {props.getFormErrorMessage('modalidad_venta')}
                    </FloatLabel>
                </div>
                {props.formik.values.tipo_op_rec?.i_ti_ope === 3 && (
                    <>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <InputText
                                    id="modalidadDNCP"
                                    type="text"
                                    value={props.formik.values.d_mod_cont}
                                    onChange={(e) => props.formik.setFieldValue('d_mod_cont', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_mod_cont', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_mod_cont') })}
                                />
                                <label htmlFor="modalidadDNCP">Modalidad - Código emitido por la DNCP</label>
                                {props.getFormErrorMessage('d_mod_cont')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <InputNumber
                                    id="entidadDNCP"
                                    value={props.formik.values.d_ent_cont}
                                    minFractionDigits={0}
                                    maxFractionDigits={0}
                                    locale="es-PY"
                                    onChange={(e) => props.formik.setFieldValue('d_ent_cont', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_ent_cont', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ent_cont') })}
                                />
                                <label htmlFor="entidadDNCP">Entidad - Código emitido por la DNCP</label>
                                {props.getFormErrorMessage('d_ent_cont')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <InputNumber
                                    id="anhoDNCP"
                                    value={props.formik.values.d_ano_cont}
                                    minFractionDigits={0}
                                    maxFractionDigits={0}
                                    locale="es-PY"
                                    onChange={(e) => props.formik.setFieldValue('d_ano_cont', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_ano_cont', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ano_cont') })}
                                />
                                <label htmlFor="anhoDNCP">Año - Código emitido por la DNCP</label>
                                {props.getFormErrorMessage('d_ano_cont')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <InputNumber
                                    id="secDNCP"
                                    value={props.formik.values.d_sec_cont}
                                    minFractionDigits={0}
                                    maxFractionDigits={0}
                                    locale="es-PY"
                                    onChange={(e) => props.formik.setFieldValue('d_sec_cont', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_sec_cont', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_sec_cont') })}
                                />
                                <label htmlFor="secDNCP">Secuencia - Código emitido por la DNCP</label>
                                {props.getFormErrorMessage('d_sec_cont')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Calendar
                                    id="fechaEmicDNCP"
                                    value={props.formik.values.d_fe_cod_cont}
                                    onChange={(e) => props.formik.setFieldValue('d_fe_cod_cont', e.target.value)}
                                    onFocus={() => props.formik.setFieldTouched('d_fe_cod_cont', true)}
                                    dateFormat="yy-mm-dd"
                                    locale="es"
                                    selectionMode={'single'}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_fe_cod_cont') })}
                                />
                                <label htmlFor="fechaEmicDNCP">Fecha de emisión del código de contratación</label>
                                {props.getFormErrorMessage('d_fe_cod_cont')}
                            </FloatLabel>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default GCamFE;
